import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Stack,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import React, { useState } from 'react';

import { TSMS } from '@/types/sms';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { BoxIcon } from '@/assets/icons/BoxIcon';
import { Send01Icon } from '@/assets/icons/Send01Icon';
import { formatFullDateTime } from '@/utils/date';
import { formatPhoneNumber } from '@/utils/phone';
import { capitalizeFirstLetter } from '@/utils/text';

interface Props {
  sms: TSMS;
  onSMSAccept?: (sms: TSMS) => void;
  onSMSArchive?: (sms: TSMS) => void;
  isSending?: boolean;
  isArchiving?: boolean;
  readOnly?: boolean;
  onViewHistory?: (sms: TSMS) => void;
}

const SMSMobileCard: React.FC<Props> = ({
  sms,
  onSMSAccept,
  onSMSArchive,
  isSending,
  isArchiving,
  readOnly,
  onViewHistory,
}) => {
  const [aiResponse, setAiResponse] = useState(sms.first_ai_message);

  const handleSend = () => {
    if (onSMSAccept) {
      onSMSAccept({
        ...sms,
        first_ai_message: aiResponse,
      });
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="body4">ID: {sms.external_id}</Typography>

          <Stack spacing={1}>
            <Typography variant="subtitle3">
              {sms.session?.tenant?.full_name
                ? capitalizeFirstLetter(sms.session.tenant.full_name)
                : 'Unknown'}
            </Typography>

            <Typography variant="body3">
              {sms.session?.from_phone_number
                ? formatPhoneNumber(sms.session.from_phone_number)
                : ''}
            </Typography>

            {sms.session?.tenant?.unit && (
              <Typography variant="body4">
                {sms.session.tenant.unit.building?.name} - {sms.session.tenant.unit.name}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle3">Message:</Typography>

            <Typography variant="body3">{sms.first_user_message}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle3">AI Response:</Typography>

            <TextField
              multiline
              fullWidth
              minRows={3}
              size="small"
              value={aiResponse}
              onChange={e => setAiResponse(e.target.value)}
            />
          </Stack>

          <Typography variant="body4">{formatFullDateTime(new Date(sms.created_at))}</Typography>

          {!readOnly && (
            <CardActions>
              <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                {onSMSAccept && (
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    startIcon={<Send01Icon />}
                    loading={isSending}
                  >
                    Send
                  </LoadingButton>
                )}
                {onSMSArchive && (
                  <LoadingButton
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    startIcon={<BoxIcon />}
                    onClick={() => onSMSArchive(sms)}
                    loading={isArchiving}
                  >
                    Archive
                  </LoadingButton>
                )}
              </Stack>
            </CardActions>
          )}

          <Stack direction="row" spacing={1} mt={2}>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => onViewHistory?.(sms)}
              fullWidth
            >
              View History
            </Button>
          </Stack>

          {readOnly && sms.session && (
            <CardActions>
              <Chip
                label={sms.session.replied_to ? 'Sent' : 'Archived'}
                icon={sms.session.replied_to ? <Send01Icon /> : <BoxIcon />}
                color={sms.session.replied_to ? 'success' : 'warning'}
                variant="light"
                size="small"
              />
            </CardActions>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SMSMobileCard;
