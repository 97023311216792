import { TDocument } from './document';
import { TTenant } from './tenant';

export interface TEmailSession {
  building_id: number;
  created_at: string;
  email_body: string;
  encrypted_value: string;
  from_email: string;
  from_name: string;
  full_hash: string;
  id: string;
  property_email: string;
  session_id: string;
  subject: string;
  tenant?: TTenant;
  tenant_id: number;
  updated_at: string;
  replied_to?: boolean;
  archived?: boolean;
}

export interface TEmail {
  thread_id: number;
  thread_external_id: string;
  thread_title: string;
  thread_property_id: string;
  thread_created_at: string;
  first_user_message: string;
  first_ai_message: string;
  last_user_message?: string;
  last_ai_message?: string;
  session?: {
    id: number;
    session_id: string;
    email_body: string;
    from_name: string;
    from_email: string;
    subject: string;
    replied_to?: boolean;
    archived?: boolean;
    tenant?: TTenant;
    documents?: TDocument[];
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Metadata = {} | null;

export enum EmailThreadRole {
  ai = 'ai',
  tenant = 'tenant',
  property_manager = 'property_manager',
}

export interface TEmailThreadEmail {
  id: number;
  message: string;
  custom_metadata: Metadata;
  updated_at: string;
  email_thread_id: number;
  role: EmailThreadRole;
  created_at: string;
}

export interface TEmailThread {
  email_thread: {
    external_id: string;
    title: string;
    tenant_id: string;
    created_at: string;
    custom_metadata: Metadata;
    id: number;
    property_id: string;
    archived: boolean;
    updated_at: string;
  };
  emails: TEmailThreadEmail[];
}

export interface TEmailTemplate {
  id: number;
  name: string;
  subject: string;
  body: string;
  propertyId: number;
  createdAt: Date;
  updatedAt: Date;
  location?: {
    name: string;
    email: string;
    phone: string;
  };
}
