import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TPropertyManager } from '@/types/propertyManager';

import { useAuth } from '@/context/AuthProvider';

interface PropertyManagerUpdateModalProps {
  onClose: () => void;
  onPropertyManagerUpdated: () => void;
  propertyManager: TPropertyManager;
}

const ROLES = ['ADMIN', 'MANAGER'] as const;
type Role = (typeof ROLES)[number];

const PropertyManagerUpdateModal: React.FC<PropertyManagerUpdateModalProps> = ({
  onClose,
  onPropertyManagerUpdated,
  propertyManager,
}) => {
  const { session } = useAuth();
  const [role, setRole] = useState<Role>(propertyManager.role.toUpperCase() as Role);
  const [isEmergencyContact, setIsEmergencyContact] = useState(
    propertyManager.is_emergency_contact
  );
  const [fullName, setFullName] = useState(propertyManager.full_name);
  const [email, setEmail] = useState(propertyManager.email);
  const [phone, setPhone] = useState(propertyManager.phone);

  const updatePropertyManagerMutation = useMutation({
    mutationFn: async () => {
      try {
        const response = await axios.put(
          `${environment.api}/property-manager/${Number(propertyManager.id)}`,
          {
            role,
            is_emergency_contact: isEmergencyContact,
            full_name: fullName,
            email,
            phone: phone || null,
          },
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          throw new Error(
            error.response?.data?.details?.message[0] || 'Failed to update property manager'
          );
        }
        throw error;
      }
    },
    onError: (error: Error) => {
      enqueueSnackbar(error.message || 'Failed to update property manager', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Property manager updated successfully', { variant: 'success' });
      onPropertyManagerUpdated();
      onClose();
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    updatePropertyManagerMutation.mutate();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Update Property Manager</DialogTitle>

        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              label="Full Name"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled
              fullWidth
            />
            <TextField
              label="Phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              fullWidth
            />
            <TextField
              select
              label="Role"
              value={role}
              onChange={e => setRole(e.target.value as Role)}
              required
              fullWidth
            >
              {ROLES.map(managerRole => (
                <MenuItem key={managerRole} value={managerRole}>
                  {managerRole.charAt(0).toUpperCase() + managerRole.slice(1)}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={
                <Switch
                  checked={isEmergencyContact}
                  onChange={e => setIsEmergencyContact(e.target.checked)}
                  color="primary"
                />
              }
              label="Emergency Contact"
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton onClick={onClose} color="secondary">
            Cancel
          </LoadingButton>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={updatePropertyManagerMutation.isPending}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PropertyManagerUpdateModal;
