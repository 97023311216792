import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Stack,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';

import { environment } from '@env';

import { TBuildingContact } from '@/types/building';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Send01Icon } from '@/assets/icons/Send01Icon';
import { Users01Icon } from '@/assets/icons/Users01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  buildingId: number;
  onClose: () => void;
  onBulkSmsSent?: () => void;
}

const SMSBulkSendModal: React.FC<Props> = ({ buildingId, onClose, onBulkSmsSent }) => {
  const [message, setMessage] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<TBuildingContact[]>([]);

  const { session } = useAuth();

  const fetchBuildingContactsQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'building', buildingId, 'contacts', session?.access_token],
    queryFn: async () => {
      try {
        const response = await axios.get<TBuildingContact[]>(
          `${environment.api}/building/${buildingId}/contacts`,
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );

        return response.data.filter(contact => contact.phone);
      } catch {
        enqueueSnackbar('Failed to fetch building contacts.', { variant: 'error' });
      }
    },
  });

  const sendBulkSmsMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        `${environment.api}/twilio/bulk-send`,
        {
          message,
          tenantIds: selectedContacts.map(contact => contact.tenantId),
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: () => {
      onBulkSmsSent?.();
      enqueueSnackbar('Bulk SMS sent successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to send bulk SMS.', { variant: 'error' });
    },
  });

  const handleSelectAll = () => {
    setSelectedContacts(fetchBuildingContactsQuery.data || []);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Send Bulk SMS</DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          label="Message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Recipients
        </Typography>

        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Users01Icon />}
            onClick={handleSelectAll}
            disabled={!fetchBuildingContactsQuery.data?.length}
          >
            Select All
          </Button>
          <Typography variant="body2" color="text.secondary">
            {selectedContacts.length} of {fetchBuildingContactsQuery.data?.length || 0} selected
          </Typography>
        </Stack>

        <Autocomplete
          multiple
          value={selectedContacts}
          onChange={(_, newValue) => setSelectedContacts(newValue)}
          options={fetchBuildingContactsQuery.data || []}
          getOptionLabel={option =>
            `${option.tenantName} (Unit ${option.unitName}) <${option.phone}>`
          }
          renderInput={params => <TextField {...params} label="Select Recipients" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          loading={fetchBuildingContactsQuery.isLoading}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          onClick={() => sendBulkSmsMutation.mutate()}
          variant="contained"
          startIcon={<Send01Icon />}
          color="primary"
          disabled={
            selectedContacts.length === 0 || !message || fetchBuildingContactsQuery.isPending
          }
          loading={sendBulkSmsMutation.isPending}
        >
          Send SMS
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SMSBulkSendModal;
