import React from 'react';

import BasePublicMuiPage from '../common/BasePublicMuiPage';

import AboutUsHeroSection from './sections/AboutUsHeroSection';
import FooterSection from './sections/FooterSection';
import HaveQuestionsSection from './sections/HaveQuestionsSection';
import NavSection from './sections/NavSection';
import OurMissionSection from './sections/OurMissionSection';
import OurValuesSection from './sections/OurValuesSection';
import OurVisionSection from './sections/OurVisionSection';
import RedefiningFutureSection from './sections/RedefiningFutureSection';

const AboutUsPage: React.FC = () => (
  <BasePublicMuiPage>
    <NavSection />

    <AboutUsHeroSection />

    <RedefiningFutureSection />

    <OurMissionSection />

    <OurVisionSection />

    {/* <TeamSection /> */}

    <OurValuesSection />

    <HaveQuestionsSection />

    <FooterSection />
  </BasePublicMuiPage>
);

export default AboutUsPage;
