import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TTenant, TTenantContact } from '@/types/tenant';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { Save01Icon } from '@/assets/icons/Save01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  tenant: TTenant;
  onClose: () => void;
  onTenantUpdated: (unit: TTenant) => void;
}

const TenantUpdateModal: React.FC<Props> = ({ tenant, onClose, onTenantUpdated }) => {
  const [fullName, setFullName] = useState(tenant.full_name);
  const [contacts, setContacts] = useState<TTenantContact[]>(tenant.contacts);

  const { session } = useAuth();

  const addContact = () => {
    setContacts(prevContacts => [
      ...prevContacts,
      {
        tenant_id: Number(tenant.id),
        email: '',
        phone: '',
        full_name: '',
        tenant: {
          id: Number(tenant.id),
          full_name: tenant.full_name || '',
          unit_name: tenant.unit?.name || '',
        },
      },
    ]);
  };

  const removeContact = (index: number) => {
    setContacts(prevContacts => prevContacts.filter((_, i) => i !== index));
  };

  const updateContact = (index: number, field: 'email' | 'phone', value: string) => {
    setContacts(prevContacts =>
      prevContacts.map((c, i) => (i === index ? { ...c, [field]: value } : c))
    );
  };

  const editTenantMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.put<TTenant>(
        `${environment.api}/tenant/${tenant.id}`,
        {
          full_name: fullName,
          contacts: contacts
            .filter(contact => contact.email || contact.phone)
            .map(contact => ({
              id: contact.id,
              email: contact.email,
              phone: contact.phone,
            })),
        },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      onTenantUpdated(data);
      enqueueSnackbar(`Tenant ${data.full_name} updated successfully.`, { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update tenant.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Edit Tenant</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2} mt={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            value={fullName}
            onChange={e => setFullName(e.target.value)}
          />

          {contacts.map((contact, index) => (
            <Stack key={contact.id} direction="column" spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  margin="dense"
                  label="Email"
                  type="email"
                  fullWidth
                  value={contact.email}
                  onChange={e => updateContact(index, 'email', e.target.value)}
                />

                <TextField
                  margin="dense"
                  label="Phone"
                  type="tel"
                  fullWidth
                  value={contact.phone}
                  onChange={e => updateContact(index, 'phone', e.target.value)}
                />

                {contacts.length > 1 && (
                  <IconButton size="small" onClick={() => removeContact(index)} sx={{ mt: 1 }}>
                    <MinusIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          ))}

          <Button
            startIcon={<PlusIcon />}
            onClick={addContact}
            variant="outlined"
            color="secondary"
          >
            Add an additional email or phone number
          </Button>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          disabled={!fullName || editTenantMutation.isPending}
          variant="contained"
          startIcon={<Save01Icon />}
          loading={editTenantMutation.isPending}
          onClick={() => editTenantMutation.mutate()}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TenantUpdateModal;
