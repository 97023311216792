import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  MenuItem,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { useAuth } from '@/context/AuthProvider';
import { supabase } from '@/utils/supabaseClient';

interface PropertyManagerCreateModalProps {
  onClose: () => void;
  onPropertyManagerCreated: () => void;
  propertyLocationId: number;
}

const ROLES = ['ADMIN', 'MANAGER'] as const;
type Role = (typeof ROLES)[number];

const PropertyManagerCreateModal: React.FC<PropertyManagerCreateModalProps> = ({
  onClose,
  onPropertyManagerCreated,
  propertyLocationId,
}) => {
  const { session } = useAuth();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState<Role>('MANAGER');

  const createPropertyManagerMutation = useMutation({
    mutationFn: async (userId: string) => {
      const response = await axios.post(
        `${environment.api}/property-manager`,
        {
          userId,
          locationId: propertyLocationId,
          role,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Generate a random password for the new user
      const tempPassword = Math.random().toString(36).slice(-8);

      // Create user in Supabase
      const { data: userData, error: userError } = await supabase.auth.signUp({
        email,
        password: tempPassword,
        phone,
        options: {
          data: {
            full_name: fullName,
          },
        },
      });

      if (userError || !userData.user) {
        throw userError || new Error('Failed to create user');
      }

      // Create property manager in backend
      await createPropertyManagerMutation.mutateAsync(userData.user.id);

      enqueueSnackbar('Property manager created successfully', { variant: 'success' });
      onPropertyManagerCreated();
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to create property manager', { variant: 'error' });
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add Property Manager</DialogTitle>

        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              label="Full Name"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              fullWidth
            />
            <TextField
              select
              label="Role"
              value={role}
              onChange={e => setRole(e.target.value as Role)}
              required
              fullWidth
            >
              {ROLES.map(managerRole => (
                <MenuItem key={managerRole} value={managerRole}>
                  {managerRole.charAt(0).toUpperCase() + managerRole.slice(1)}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton onClick={onClose} color="secondary">
            Cancel
          </LoadingButton>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={createPropertyManagerMutation.isPending}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PropertyManagerCreateModal;
