import { User } from '@supabase/supabase-js';

export const getFullName = (user?: User | null): string => user?.user_metadata.full_name ?? 'User';

export const getInitialsFromFullName = (fullName: string): string =>
  fullName
    ? fullName
      .split(' ')
      .map(chunk => chunk[0])
      .join('')
      .toUpperCase()
    : '';

export const getInitials = (user?: User | null): string =>
  getInitialsFromFullName(getFullName(user));

export const getEmail = (user?: User | null): string => user?.email ?? '';
