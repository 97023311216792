import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

import { TPropertyManager } from '@/types/propertyManager';

interface PropertyManagerDeleteModalProps {
  onClose: () => void;
  onDeleted: () => void;
  propertyManager: TPropertyManager;
}

const PropertyManagerDeleteModal: React.FC<PropertyManagerDeleteModalProps> = ({
  onClose,
  onDeleted,
  propertyManager,
}) => (
  <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Delete Property Manager</DialogTitle>

    <DialogContent>
      <Typography>
        Are you sure you want to delete {propertyManager.full_name} from this property? This action
        cannot be undone.
      </Typography>
    </DialogContent>

    <DialogActions>
      <LoadingButton onClick={onClose} color="secondary">
        Cancel
      </LoadingButton>

      <LoadingButton onClick={onDeleted} color="error" variant="contained">
        Delete
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export default PropertyManagerDeleteModal;
